import styles from './Section2.module.scss';
import Banknotes from '../../../images/graphics/Banknotes.png';
import { WidthWrapper } from '../../common/WidthWrapper/WidthWrapper';
import { Typography } from '../../common/Typography/Typography';

export const Section2 = () => {
  return (
    <WidthWrapper className={styles.widthWrapper}>
      <div className={styles.root}>
        <div className={styles.leftColumn}>
          <img src={Banknotes} alt="" className={styles.image} />
        </div>
        <div className={styles.rightColumn}>
          <Typography variant="h1">Islamic Coin</Typography>
          <Typography className={styles.subtitle}>
            Islamic Coin is the native currency of HAQQ, an ethics-first, scalable, and
            interoperable blockchain built on Proof-of-Stake with fast finality.
            <br />
            <br />
            HAQQ's mission is to balance Shariah-compliant philosophy with cutting-edge technology
            to create a fairer, more sustainable financial system.
          </Typography>
        </div>
      </div>
    </WidthWrapper>
  );
};
