import { useTranslation } from 'react-i18next';

import { Typography } from '../../common/Typography/Typography';
import { WidthWrapper } from '../../common/WidthWrapper/WidthWrapper';
import styles from './MainSection.module.scss';
import Roger from '../../../images/TeamPage/Roger.png';
import Nikolai from '../../../images/TeamPage/Nikolai.png';
import Arslan from '../../../images/TeamPage/Arslan.png';
import Anton from '../../../images/TeamPage/Anton.jpeg';
import Yulia from '../../../images/TeamPage/Yulia.png';
import IlyaK from '../../../images/TeamPage/IlyaK.png';
import Andrei from '../../../images/TeamPage/Andrei.png';
import Alexey from '../../../images/TeamPage/Alexey.png';
import IliaV from '../../../images/TeamPage/IliaV.png';
import SergeiZ from '../../../images/TeamPage/SergeiZ.png';
import Yaroslav from '../../../images/TeamPage/Yaroslav.png';
import Aldiyar from '../../../images/TeamPage/Aldiyar.png';
import Malkov from '../../../images/TeamPage/Malkov.png';
import Cristina from '../../../images/TeamPage/Cristina.png';
import Domantas from '../../../images/TeamPage/Domantas.png';
import Janis from '../../../images/TeamPage/Janis.jpg';
import IliaT from '../../../images/TeamPage/IliaT.jpg';
import Nida from '../../../images/TeamPage/Nida.jpg';
import Viktoria from '../../../images/TeamPage/Viktoria.png';
import Roman from '../../../images/TeamPage/Roman.png';
import Deimante from '../../../images/TeamPage/Deimante.png';
import Karolina from '../../../images/TeamPage/Karolina.png';

const Team = [
  {
    title: 'Management',
    members: [
      {
        src: Roger,
        name: 'Roger Buerli',
        position: 'Chief Executive Officer',
        ex: 'Ex-Credit Suisse & Mirabaud',
      },
      {
        src: Nikolai,
        name: 'Nick Denisenko',
        position: 'Co-founder & CTO',
        ex: 'Ex-Revolut',
      },
      {
        src: Anton,
        name: 'Anton Axentiuc',
        position: 'Chief Operating Officer',
      },
      {
        src: Arslan,
        name: 'Arslan Ibrahim',
        position: 'Chief Product Officer',
        ex: 'Ex-Deloitte',
      },
      {
        src: Cristina,
        name: 'Cristina Stavila',
        position: 'Chief Marketing Officer',
      },
      {
        src: Viktoria,
        name: 'Viktorija Kizik',
        position: 'Chief Human Resources Officer',
      },
    ],
  },
  {
    title: 'Legal & Compliance',
    members: [
      {
        src: Malkov,
        name: 'Alex Malkov',
        position: 'CLO',
      },
      {
        src: Domantas,
        name: 'Domantas Abromavicius',
        position: 'AML Team lead',
      },
      {
        src: Yulia,
        name: 'Yulia Pyatina',
        position: 'In-house Lawyer',
      },
      {
        src: Roman,
        name: 'Roman Rashimas',
        position: 'Money Laundering Reporting Officer',
      },
    ],
  },
  {
    title: 'Sales',
    members: [
      {
        src: Janis,
        name: 'Janis Lubins',
        position: 'Sales director',
      },
      {
        src: IliaT,
        name: 'Ilia Toropovsky',
        position: 'Sales manager',
      },
    ],
  },
  {
    title: 'Customer Support Team',
    members: [
      {
        src: Nida,
        name: 'Nida Kruke',
        position: 'Head of Customer Success',
      },
      {
        src: Deimante,
        name: 'Deimante Petrovaite',
        position: 'Customer Support Specialist',
      },
      {
        src: Karolina,
        name: 'Karolina Kolosovaite',
        position: 'Customer Support Specialist',
      },
    ],
  },
  {
    title: 'Design & Development',
    members: [
      {
        src: Andrei,
        name: 'Andrei Babushkin',
        position: 'Web Developer',
      },
      {
        src: IliaV,
        name: 'Ilia Voitsekhovskii',
        position: 'Android Developer',
      },
      {
        src: SergeiZ,
        name: 'Sergey Zarotshentsev',
        position: 'Android Developer',
        ex: 'Ex-Raiffeisen',
      },
      {
        src: Yaroslav,
        name: 'Yaroslav Minaev',
        position: 'iOS Developer',
        ex: 'Ex-Revolut',
      },
      {
        src: IlyaK,
        name: 'Ilya Kisliakovskii',
        position: 'Backend Developer',
        ex: 'Ex-Revolut',
      },
      {
        src: Alexey,
        name: 'Alexey Dergachev',
        position: 'DevOps Engineer',
        ex: 'Ex-Raiffeisen',
      },
      {
        src: Aldiyar,
        name: 'Aidash Aldiyar',
        position: 'Product Designer',
      },
    ],
  },
];

export const MainSection = () => {
  const { t } = useTranslation();

  return (
    <WidthWrapper className={styles.root}>
      <Typography variant="h1" className={styles.title} align="center">
        {t('teamPage.mainSection.title')}
      </Typography>
      {/* <Typography variant="h2" className={styles.members} align="center">
        {t('teamPage.mainSection.subtitle')}
      </Typography> */}
      <div className={styles.blocks}>
        {Team.map((block) => (
          <div className={styles.block} key={block.title}>
            <Typography
              variant="h2"
              className={styles.blockTitle}
              align="center"
              alignMobile="left"
            >
              {block.title}
            </Typography>
            <div className={styles.cards}>
              {block.members.map((member) => (
                <div className={styles.card} key={member.name}>
                  <img src={member.src} alt={member.name} />
                  <div className={styles.text}>
                    <Typography variant="h4">{member.name}</Typography>
                    <Typography variant="pSmallRegular" color="var(--text2)">
                      {member.position}
                    </Typography>
                    {!!member.ex && (
                      <Typography variant="pSmallRegular" color="var(--text2)">
                        {member.ex}
                      </Typography>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </WidthWrapper>
  );
};
