import styles from './Section1.module.scss';
import Card from '../../../images/IslamicPage/Card.png';
import { WidthWrapper } from '../../common/WidthWrapper/WidthWrapper';
import { Typography } from '../../common/Typography/Typography';
import Pattern from '../../../images/IslamicPage/Pattern.svg';
import { Button } from '../../common/Button/Button';

export const Section1 = () => {
  return (
    <WidthWrapper className={styles.wrapper}>
      <div className={styles.root}>
        <div className={styles.text}>
          <Typography variant="h1" className={styles.title} align="center" alignMobile="center">
            ISLM&nbsp;
            <img src={Pattern} alt="" className={styles.newLogo} />
            <br />
            Digital Crypto Card
          </Typography>
        </div>
        <Typography variant="h3" color="var(--text3)" align="center" alignMobile="center">
          Spend, save and earn crypto & fiat with the ISLM Brighty card
        </Typography>
        <img src={Card} alt="Card" className={styles.card} />
        <Button
          href="https://brightyapp.go.link?adj_t=1fd4v437"
          target="_blank"
          title="Order ISLM Card"
        />
      </div>
    </WidthWrapper>
  );
};
