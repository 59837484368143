import { Typography } from '../../common/Typography/Typography';
import { WidthWrapper } from '../../common/WidthWrapper/WidthWrapper';
import styles from './Section5.module.scss';

export const Section5 = () => {
  return (
    <WidthWrapper className={styles.widthWrapper}>
      <div className={styles.root}>
        <Typography variant="h2" align="center" alignMobile="center">
          Ethical Standards
        </Typography>
        <Typography variant="pMediumRegular" align="center" alignMobile="center">
          As part of Islamic Coin's ethical standards, purchases from certain merchants, including
          but not limited to alcohol stores, adult content, and gambling merchants, will be declined
          with the ISLM card.
        </Typography>
      </div>
    </WidthWrapper>
  );
};
