import { lazy } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import CookiePolicyPage from '../containers/CookiePolicyPage';
import CryptocurrencyOfferingTermsAndConditions from '../containers/CryptocurrencyOfferingTermsAndConditions';
import EarningVaultTermsAndConditionsPage from '../containers/EarningVaultTermsAndConditionsPage';
import HomePage from '../containers/HomePage';
import ImprintPage from '../containers/ImprintPage';
import PrivacyPolicyPage from '../containers/PrivacyPolicyPage';
import TermsAndConditionsPage from '../containers/TermsAndConditionsPage';
import TermsAndPoliciesPage from '../containers/TermsAndPoliciesPage';
import { MainLayout } from '../layouts';
import { NotFoundPage } from '../containers/404';
import PayPage from '../containers/PayPage';
import CompanyPage from '../containers/CompanyPage';
import { BusinessPage } from '../containers/BusinessPage';
import { CareersPage } from '../containers/CareersPage';
import TeamPage from '../containers/TeamPage';
import PaymentCardTermsAndConditionsPage from '../containers/PaymentCardTermsAndConditionsPage';
import { ProofOfReservesPage } from '../containers/ProofOfReservesPage';
import GooglePayPage from '../containers/GooglePayPage';
import ApplePayPage from '../containers/ApplePayPage';
import SubscriptionTermsAndConditionsPage from '../containers/SubscriptionTermsAndConditionsPage';
import ReferralTermsAndConditionsPage from '../containers/ReferralTermsAndConditionsPage';
import InvestmentOfferingTermsAndConditionsPage from '../containers/InvestmentOfferingTermsAndConditionsPage';
import InvestRiskNoticePage from '../containers/InvestRiskNoticePage';
import AIInvestPage from '../containers/AIInvestPage';
import TonPage from '../containers/TonPage';
import AffiliatePage from '../containers/AffiliatePage';
import IslamicPage from '../containers/IslamicPage';

const InvitePage = lazy(async () => import('../containers/Invite/InvitePage'));

export const AppRouter = () => {
  return (
    <Routes>
      <Route
        element={
          <MainLayout>
            <Outlet />
          </MainLayout>
        }
      >
        <Route path="/" Component={HomePage} />
        <Route path="/business" Component={BusinessPage} />
        <Route path="/company" Component={CompanyPage} />
        <Route path="/affiliate" Component={AffiliatePage} />
        <Route path="/earnapp" Component={HomePage} />
        <Route path="/careers" Component={CareersPage} />
        <Route path="/termsAndPolicies" Component={TermsAndPoliciesPage} />
        <Route path="/imprint" Component={ImprintPage} />
        <Route path="/googlePay" Component={GooglePayPage} />
        <Route path="/applePay" Component={ApplePayPage} />
        <Route path="/team" Component={TeamPage} />
        <Route path="/invest" Component={AIInvestPage} />
        <Route path="/ai-invest" element={<Navigate to="/invest" replace />} />
        <Route path="/ton" Component={TonPage} />
        <Route path="/islamic" Component={IslamicPage} />
        <Route path="/promo/:code" Component={InvitePage} />
        <Route path="/pay/*" Component={PayPage} />
        <Route path="/profile/*" Component={PayPage} />
        <Route path="/proof-of-reserves" Component={ProofOfReservesPage} />
        <Route path="/download" element={<Navigate to="/" replace />} />
      </Route>
      <Route path="/privacy-policy" Component={PrivacyPolicyPage} />
      <Route
        path="/earning-vault-terms-and-conditions"
        Component={EarningVaultTermsAndConditionsPage}
      />
      <Route
        path="/subscription-terms-and-conditions"
        Component={SubscriptionTermsAndConditionsPage}
      />
      <Route
        path="/cryptocurrency-offering-terms-and-conditions"
        Component={CryptocurrencyOfferingTermsAndConditions}
      />
      <Route path="/fiat-terms-and-conditions" Component={TermsAndConditionsPage} />
      <Route path="/referral-terms-and-conditions" Component={ReferralTermsAndConditionsPage} />
      <Route
        path="/payment-card-terms-and-conditions"
        Component={PaymentCardTermsAndConditionsPage}
      />
      <Route path="/invest-risk-notice" Component={InvestRiskNoticePage} />
      <Route
        path="/investment-offering-terms-and-conditions"
        Component={InvestmentOfferingTermsAndConditionsPage}
      />
      <Route path="/cookie-policy" Component={CookiePolicyPage} />
      <Route path="*" Component={NotFoundPage} />
    </Routes>
  );
};
