import { WidthWrapper } from '../../common/WidthWrapper/WidthWrapper';
import styles from './Section4.module.scss';
import IslamicCard from '../../../images/IslamicPage/IslamicCard.png';
import { Typography } from '../../common/Typography/Typography';
import { NavLink } from 'react-router-dom';
import InstagramIcon from '../../../images/Instagram.svg';
import TwitterIcon from '../../../images/Twitter.svg';
import LinkedinIcon from '../../../images/Linkedin.svg';
import TelegramIcon from '../../../images/Telegram.svg';

export const Section4 = () => {
  return (
    <WidthWrapper className={styles.widthWrapper}>
      <div className={styles.root}>
        <div className={styles.card1}>
          <div className={styles.top}>
            <img src={IslamicCard} alt="TonCard" className={styles.tonCard} />
          </div>
          <div className={styles.bottom}>
            <Typography variant="h3">
              Get the <br />
              ISLM Card
            </Typography>
            <a
              href="https://brightyapp.go.link?adj_t=1fd4v437"
              target="_blank"
              rel="noreferrer"
              className={styles.buttonLink}
            >
              <button className={styles.button}>Get card</button>
            </a>
          </div>
        </div>
        <div className={styles.card2}>
          <Typography variant="h3">Join us</Typography>
          <div className={styles.socials}>
            <NavLink
              to="https://www.linkedin.com/company/brightyapp"
              className={styles.socialLink}
              target="_blank"
            >
              <img src={LinkedinIcon} alt="Linkedin" />
            </NavLink>
            <NavLink
              to="https://twitter.com/brightyapp"
              className={styles.socialLink}
              target="_blank"
            >
              <img src={TwitterIcon} alt="Twitter" />
            </NavLink>
            <NavLink
              to="https://instagram.com/brighty.app"
              className={styles.socialLink}
              target="_blank"
            >
              <img src={InstagramIcon} alt="Instagram" />
            </NavLink>
            <NavLink to="https://t.me/brightyapp" className={styles.socialLink} target="_blank">
              <img src={TelegramIcon} alt="Telegram" />
            </NavLink>
          </div>
        </div>
        <div className={styles.card3}>
          <Typography variant="h3">Only available for EEA residents.</Typography>
          <Typography variant="pSmallRegular">
            ©2022-2024. Razz Finanzz AG. Brighty Digital UAB.
          </Typography>
        </div>
      </div>
    </WidthWrapper>
  );
};
